/*!
Author: Digitec
Author URI: https://www.digitecintl.com/
*/
* {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-decoration: none;
  outline: none;
  /* user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; */
  -webkit-tap-highlight-color: transparent;
}
input,
textarea,
select,
button {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.clear {
  clear: both;
}
.loader-box {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #fff;
  z-index: 999999;
}
.loader-box .loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  border: 2px solid #ed1c24;
  top: 50%;
  animation: loader 2s infinite ease;
}
.loader-box .loader .loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #ed1c24;
  animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
